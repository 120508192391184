import { HopeTabTypes } from '@core/hopes';
import { useWindowSize } from '@web/components/use-window-size';
import { useUserData } from '@web/features/users/use-user-data';
import { TILTSwal } from '@web/services/swal';
import * as React from 'react';
import { TMApi } from '@web/services/tmapi';
import {
  hopesDeniedSwal,
  meditationsDeniedSwal,
  timeMachineDeniedSwal,
} from './swals/denied';
import { welcomeSwal } from './swals/welcome';
import { newsletterSwal } from './swals/newsletter';

const BASE_IMAGE_URL = '/images/map';

export function useMapStatus() {
  const [{ userStatus, userData }] = useUserData();
  const { shouldListen, shouldRecord } = userStatus?.f1 || {};

  const { width, height } = useWindowSize();

  const aspectRatio = (height || 1) / (width || 1);

  /** Change these values to tweak the breakpoints for most of the map's changes */
  const isLandscape = aspectRatio < 1.0;
  const isSquarish = aspectRatio < 1.4;
  const isUltrawide = aspectRatio < 0.4;
  const isPortrait = !isLandscape;

  const isFirstTime =
    userData &&
    userData.has_onboarded &&
    !!userData.last_wellness_check &&
    userData.f1_last_recording === null &&
    userData.f1_rolling_days === 0;

  let askIfWantsToSubscribe =
    userData &&
    userData.has_onboarded &&
    !userStatus?.global.shouldWellness &&
    userData.derived_properties.newsletter_subscription_status ===
      'NOT_SUBSCRIBED';

  const isTimeMachineUnlocked =
    (userStatus?.f1.shouldRecord || userStatus?.f1.shouldListen) ?? false;
  const isMeditationsUnlocked = userStatus?.f2.isUnlocked ?? false;
  const isHopesUnlocked = userStatus?.f3.isUnlocked ?? false;

  React.useEffect(() => {
    const isSwalOpen = TILTSwal.isVisible();

    if (!isSwalOpen && isFirstTime) {
      welcomeSwal();
    }
    if (!isSwalOpen && !isFirstTime && askIfWantsToSubscribe) {
      newsletterSwal().then((result) => {
        const newStatus = result.isConfirmed ? 'SUBSCRIBED' : 'UNSUBSCRIBED';
        userData.derived_properties.newsletter_subscription_status = newStatus;
        const tmapi = new TMApi();
        tmapi.setNewsletterSubStatus(newStatus);
      });
    }
  }, [isFirstTime, askIfWantsToSubscribe]);

  const background = React.useMemo(() => {
    const mode: 'portrait' | 'landscape' = isPortrait
      ? 'portrait'
      : 'landscape'; // should eventually include 'squarish' and 'ultrawide'

    const lockedStatus = {
      timeMachine: 'locked',
      meditations: isMeditationsUnlocked ? 'unlocked' : 'locked',
      hopes: isHopesUnlocked ? 'unlocked' : 'locked',
    };

    if (isTimeMachineUnlocked && shouldListen) {
      lockedStatus.timeMachine = 'listen';
    } else if (isTimeMachineUnlocked && shouldRecord) {
      lockedStatus.timeMachine = 'record';
    }

    return `${BASE_IMAGE_URL}/${mode}/f1-${lockedStatus.timeMachine}--f2-${lockedStatus.meditations}--f3-${lockedStatus.hopes}.png`;
  }, [
    isHopesUnlocked,
    isMeditationsUnlocked,
    isPortrait,
    isTimeMachineUnlocked,
    shouldListen,
    shouldRecord,
  ]);

  return {
    background,
    isLandscape,
    isSquarish,
    isUltrawide,

    status: {
      timemachine: {
        overlay: <span className="overlay" />,
        isUnlocked: isTimeMachineUnlocked,
        // eslint-disable-next-line no-nested-ternary
        route: userStatus?.f1.shouldListen
          ? '/listen'
          : userStatus?.f1.shouldRecord
          ? '/record'
          : '/',
        lockedMessage: timeMachineDeniedSwal,
      },

      meditations: {
        overlay: <span className="overlay" />,
        isUnlocked: isMeditationsUnlocked,
        route: isMeditationsUnlocked ? '/meditations' : '/',
        lockedMessage: meditationsDeniedSwal,
      },

      hopes: {
        overlay: <span className="overlay" />,
        isUnlocked: isHopesUnlocked,
        route: isHopesUnlocked ? `/hopes?tab=${HopeTabTypes.MY_GARDEN}` : '/',
        lockedMessage: hopesDeniedSwal,
      },
    },
  };
}
