import { AvatarWithData, DefaultAvatar, DefaultAvatarSlug } from './avatars';
import { Metadata, UploadMetadata } from './metadata';
import { Resource } from './resources';

export type CommunityWateringDoc = Metadata & {
  last_updated: string;
  gv_today: number;
};

export type CommunityWaterLevels = 0 | 1 | 2 | 3;

export type CheckinIntervals = 'Custom' | 'Weekly' | 'Monthly';

export type DaysOfTheWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export type CheckinValues =
  // weekly
  | DaysOfTheWeek
  // monthly
  | number
  // custom date string
  | string;

export type Hope = Metadata & {
  id: string;
  status: 'PENDING' | 'READY' | 'COMPLETED' | 'REJECTED' | 'ARCHIVED' | 'ERROR';
  name: string;
  type: 'PUBLIC' | 'PRIVATE';
  error: null | string;
  reports: number;
  resource_ids: string[];
  recording_metadata: null | UploadMetadata;
  recording_output: null | string;
  checkin_interval: CheckinIntervals;
  checkin_value: null | CheckinValues;
  checkin_count: number;
  last_checkin: string;
  avatar_slug: DefaultAvatarSlug;
  harvest_date: null | Date;

  recording_url?: string;

  /** @deprecated use avatar_slug */
  avatar_id?: string;
};

export type HopeWithData = Hope & {
  id: string;
  resources?: null | Array<Resource & { id: string }>;
  recordingUrl?: null | string;
  defaultAvatar: null | DefaultAvatar;

  /** @deprecated use defaultAvatar */
  avatar?: null | AvatarWithData;
};

export type HopeTabTypes = typeof HopeTabTypes[keyof typeof HopeTabTypes];
export const HopeTabTypes = {
  MY_GARDEN: 'MY_GARDEN',
  COMMUNITY_GARDEN: 'COMMUNITY_GARDEN',
  MY_HARVEST: 'MY_HARVEST',
  // REPORTED: 'REPORTED',
} as const;

export type ReportTypes = typeof ReportTypes[keyof typeof ReportTypes];
export const ReportTypes = {
  MUTE_HOPE: 'mute this hope',
  MUTE_USER: 'mute this user',
  FLAG_HOPE: 'flag this hope',
} as const;

export type HopeReport = Metadata & {
  type: typeof ReportTypes.FLAG_HOPE;
  hope: Partial<Hope>;
  totalReports: number;
};

export type HopeDataConfig = {
  resources?: boolean;
  recording?: boolean;
  defaultAvatar?: boolean;

  /** pass the id of the last active document to paginate through */
  lastActive?: string | null;

  /** limit the number of documents */
  limit?: number;

  /** @deprecated use defaultAvatar */
  avatar?: boolean;
};

export const falseHopes = ['ARCHIVE', 'ARCHIVED', 'ERROR', 'REJECTED'] as const;

/** The maximum amount of reports a hopes can have before it's pulled from the community garden. */
export const MAX_REPORTS_FOR_PUBLIC = 1;
export const MAX_COMMUNITY_HOPES = 3;

// take a number, turn into a date, and return the date for 4 days ago
// function getFourDaysAgo(date) {
//   const fourDaysAgo = new Date(date);
//   fourDaysAgo.setDate(fourDaysAgo.getDate() - 4);
//   return fourDaysAgo.getTime();
// }
