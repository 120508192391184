import { defaultClassNames, TILTSwal } from '@web/services/swal';

/** Swal to be fired the first time a user opens the app. */
export const welcomeSwal = () =>
  TILTSwal.fire({
    imageUrl: '/images/popup-headers/f1-all.png',
    confirmButtonText: 'Begin',
    customClass: {
      ...defaultClassNames,
      popup: `full-image ${defaultClassNames.popup}`,
      image: `full-image`,
    },

    title: 'Welcome to your Time Machine',

    html: (
      <div>
        <p>
          This is your place to love and connect with yourself over time. To
          start, click on the Time Machine.
        </p>
      </div>
    ),
  });
