/* eslint-disable jsx-a11y/label-has-associated-control */
import { css } from '@emotion/react';
import { useMapStatus } from '@web/components/use-map-status';
import { UserLayout } from '@web/features/layouts/user';
import { buttonAnimation, theme, variantProps } from '@web/features/theme';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';

/**
 * TheMap
 * @packageDescription
 *
 * An empty page that redirects to the user directory on the client-side.
 * Bad for SEO, Implemented because of our `_app` and `layout__*`
 *
 * Aspect Ratio = height / width
 * - The larger the decimal number, the wider the image
 * - The smaller the decimal number, the taller the image
 */
export default function TheMap() {
  const { status, background, isLandscape, isSquarish, isUltrawide } =
    useMapStatus();

  return (
    <UserLayout fullWidth fullHeight withShare>
      <section css={styles.mapLayout}>
        <Image
          src={background}
          layout="fill"
          objectFit="cover"
          objectPosition="center 65%"
          alt="Background image of Time Machine, showing the different stages of the app"
        />

        {Object.entries(status).map(([key, data]) => (
          <Link key={key} href={data.route} passHref legacyBehavior>
            <motion.a
              css={styles[key as keyof typeof status]({
                isLandscape,
                isSquarish,
                isUltraWide: isUltrawide,
              })}
              className={[data.isUnlocked ? 'active' : 'disabled', key].join(
                ' ',
              )}
              onClick={data.isUnlocked ? undefined : data.lockedMessage}
              variants={buttonAnimation}
              {...variantProps}
            >
              <span className="overlay" />
            </motion.a>
          </Link>
        ))}
      </section>
    </UserLayout>
  );
}

const styles = {
  mapLayout: css`
    position: relative;
    height: 100vh;
    width: 100vw;
    background-color: ${theme.colors.background};

    & > a {
      position: absolute;
      margin: 0;
      padding: 0;

      .overlay {
        border-radius: ${theme.radii.full};
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  `,

  timemachine: ({
    isLandscape,
    isSquarish,
    isUltraWide,
  }: {
    isLandscape: boolean;
    isSquarish: boolean;
    isUltraWide: boolean;
  }) => css`
    right: 0%;
    bottom: 0%;
    height: 35%;
    width: 55%;

    ${isSquarish && css``}

    ${isLandscape &&
    css`
      height: 55%;
      width: 25%;
    `}

    ${isUltraWide &&
    css`
      height: 70%;
    `}
  `,

  meditations: ({
    isLandscape,
    isSquarish,
    isUltraWide,
  }: {
    isLandscape: boolean;
    isSquarish: boolean;
    isUltraWide: boolean;
  }) => css`
    left: 0%;
    bottom: 36%;
    width: 100%;
    height: 20%;

    ${isSquarish &&
    css`
      bottom: 40%;
      height: 30%;
    `}

    ${isLandscape &&
    css`
      bottom: 25%;
      height: 30%;
      width: 44%;
      clip-path: polygon(50% 0, 50% 40%, 100% 40%, 100% 100%, 0 100%, 0 0);
    `}

    ${isUltraWide &&
    css`
      height: 50%;
    `}
  `,

  hopes: ({
    isLandscape,
    isSquarish,
    isUltraWide,
  }: {
    isLandscape: boolean;
    isSquarish: boolean;
    isUltraWide: boolean;
  }) => css`
    left: 0%;
    bottom: 57%;
    height: 25%;
    width: 100%;

    ${isSquarish &&
    css`
      bottom: initial;
      top: 0;
      height: 25%;
    `}

    ${isLandscape &&
    css`
      top: initial;
      left: 25%;
      bottom: 45%;
      height: 20%;
      width: 47%;
    `}

    ${isUltraWide &&
    css`
      top: 0;
      height: 50%;
    `}
  `,
};
