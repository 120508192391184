import { MIN_DAYS_FOR_F2, MIN_DAYS_FOR_F3 } from '@core/utils/env';
import { TILTSwal } from '@web/services/swal';

/** Swal to be fired whenever access to the hope garden is restricted */
export const hopesDeniedSwal = () =>
  TILTSwal.fire({
    imageUrl: '/images/map/icons/hope-garden.png',
    imageWidth: 300,

    html: (
      <div>
        <p>
          We found that people need to use the time machine for&nbsp;
          {MIN_DAYS_FOR_F3} days before they are ready to use the hope garden.
          So please continue to use the time machine and you&apos;ll have access
          soon!
        </p>
      </div>
    ),
  });

/** Swal to be fired whenever access to the meditation hut is restricted */
export const meditationsDeniedSwal = () =>
  TILTSwal.fire({
    imageUrl: '/images/map/icons/meditation-icon.png',
    imageWidth: 300,
    html: (
      <div>
        <p>
          We found that people need to use the time machine for&nbsp;
          {MIN_DAYS_FOR_F2} days before they are ready to use the meditation
          tent. So please continue to use the time machine and you&apos;ll have
          access soon!
        </p>
      </div>
    ),
  });

/** Swal to be fired whenever acces to the time machine is restricted */
export const timeMachineDeniedSwal = () =>
  TILTSwal.fire({
    imageUrl: '/images/map/icons/launched-icon.png',
    imageWidth: 200,
    title: <p css={{ textAlign: 'center' }}>Your Time Machine has launched.</p>,
    html: (
      <div css={{ textAlign: 'center' }}>
        <p>Come back tomorrow to receive your message.</p>
      </div>
    ),
  });
