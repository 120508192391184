import { defaultClassNames, TILTSwal } from '@web/services/swal';

export const newsletterSwal = () =>
  TILTSwal.fire({
    imageUrl: '/images/popup-headers/f1-all.png',
    confirmButtonText: 'Subscribe',

    denyButtonText: 'No thanks',
    showDenyButton: true,

    customClass: {
      ...defaultClassNames,
      popup: `full-image ${defaultClassNames.popup}`,
      image: `full-image`,
    },

    title: 'Subscribe to our Newsletter',

    html: (
      <div>
        <p>
          Stay in tune with new developments in Love and Time by subscribing to
          our newsletter!
        </p>
        <p>
          Because we love and respect you, we will never, ever sell your email
          address or use it for anything but communication.
        </p>
      </div>
    ),
  });
